<template>
  <WeContainer v-if="ready" card="">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0"><i class="far fa-copyright"></i> {{ getTitle }}</h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="blog.lang" />
            </div>
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <!-- Title -->
      <WeInput
        label="Başlık"
        v-model="form.title"
        v-bind:required="true"
        v-bind:error="$v.form.title.$error"
      />
      <!-- ./Title -->

      <!-- Slug -->
      <WeSlugCheck
        v-bind:value="form.title"
        v-bind:id="$route.params.id"
        v-bind:slug="form.slug"
        v-bind:search="true"
        v-on:slugify="changeSlug"
        type="blog"
      />
      <!-- ./Slug -->

      <!-- Is Active -->
      <div class="d-block mb-3">
        <label for="date-picker" class="custom-label">Yayınlanma Tarihi</label>
        <WeDatePicker
          id="date-picker"
          label="Tarih"
          v-bind:format="'DD.MM.YYYY HH:mm'"
          v-bind:output-format="'YYYY-MM-DD HH:mm'"
          v-bind:locale="'tr'"
          v-bind:no-label="true"
          v-bind:no-button-now="true"
          v-model="form.published_at"
        />
      </div>
      <div class="d-block mb-3">
        <label class="custom-label">Blog Durumu</label>
        <WeSwitch v-model="form.is_active" v-bind:show-status="true" />
      </div>
      <!-- ./Is Active -->

      <!-- Featured Image -->
      <WeImageUploader
        v-model="form.image"
        v-bind:image-required="true"
        v-bind:fixed-meta-alt="form.title"
        v-bind:fixed-meta-title="form.title"
        title="Blog Görseli"
        ref="imageuploader"
      />
      <!-- ./Featured Image -->

      <!-- Content -->
      <WeEditor
        name="blog"
        v-model="form.content"
        title="İçerik"
        v-bind:accessToken="session.accessToken"
      />
      <!-- ./Content -->

      <!-- Meta Data -->
      <WeMetaData v-model="form.meta" />
      <!-- ./Meta Data -->

      <!-- Submit -->
      <div class="col-12 mt-4 text-center">
        <WeSubmitButton
          v-bind:submit-status="submit"
          v-bind:update="$route.params.id"
          v-bind:permission="checkPermission"
          v-on:submit="submitForm"
        />
      </div>
      <!-- ./Submit -->

      <WeSeoBar
        v-bind:focus-keyword="form.title"
        v-bind:metaTitle="form.meta.title"
        v-bind:metaDescription="form.meta.description"
        v-bind:description="form.content"
        v-bind:slug="form.slug"
      />

      <div
        class="row"
        v-if="this.config['app.name_tenant'] == 'ugursaat' && $route.params.id"
      >
        <div class="col-12 mb-3 mt-3">
          <hr />
          <h4>Rolex Ürün Görselleri</h4>
        </div>
        <div class="col-6">
          <WeImageUploader
            v-model="form.rolex_desktop"
            :title="`Rolex Blog Masaüstü Görseli`"
            v-bind:image-required="true"
            v-bind:fixed-meta-alt="form.title"
            v-bind:fixed-meta-title="form.title"
            :acceptedMimeTypes="'.png,.jpg,.jpeg,.webp'"
          />
        </div>

        <div class="col-6">
          <WeImageUploader
            v-model="form.rolex_mobile"
            :title="`Rolex Blog Mobile Görseli`"
            v-bind:image-required="true"
            v-bind:fixed-meta-alt="form.title"
            v-bind:fixed-meta-title="form.title"
            :acceptedMimeTypes="'.png,.jpg,.jpeg,.webp'"
          />
        </div>
        <div class="col-12 text-center mt-4">
          <WeSubmitButton
            :btnText="'Görselleri Yükle'"
            v-bind:submit-status="saveImageSubmit"
            v-on:submit="onSaveImage"
          />
        </div>
      </div>
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      submit: false,
      ready: true,
      saveImageSubmit: false,
      rolexImages: {
        mobile: {
          files: [],
          filesData: null,
        },
        desktop: {
          files: [],
          filesData: null,
        },
      },
      form: {
        title: null,
        slug: null,
        content: null,
        is_active: true,
        published_at: null,
        meta: {
          title: null,
          keywords: null,
          description: null,
          robots: ["index", "follow"],
        },
        image: {
          files: [],
          filesData: null,
        },
        rolex_desktop: {
          files: [],
          filesData: null,
        },
        rolex_mobile: {
          files: [],
          filesData: null,
        },
      },
      cleanData: {},
      cleanRequest: {},
    };
  },
  methods: {
    ...mapActions("blog", ["create", "update", "delete", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    ...mapActions({
      saveRolexBlogImage: "blog/saveRolexBlogImage",
    }),
    onSaveImage() {
      if (
        !this.form.rolex_desktop.files.length ||
        !this.form.rolex_mobile.files.length
      ) {
        this.$toast.error("Lütfen mobil ve desktop görsellerini giriniz");
        return;
      }
      this.saveImageSubmit = true;
      this.saveRolexBlogImage({
        desktop: this.form.rolex_desktop,
        mobile: this.form.rolex_mobile,
        id: this.$route.params.id,
        onSuccess: (result) => {
          if (result?.data?.error) {
            this.$toast.error(result?.data?.error);
          } else if (result?.data?.status) {
            this.$toast.success("Görsel yükleme işlemi tamamlandı");
            location.reload()
          }
        },
        onError: () => {},
        onFinish: () => {
          this.saveImageSubmit = false;
        },
      });
    },
    validateForm() {
      this.$v.$touch();
      let imageValid = this.$refs.imageuploader.$refs.single.$v;
      imageValid.$touch();

      if (this.$v.$invalid || imageValid.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.cleanRequest = helper.difference(this.form, this.cleanData, [
          "image",
        ]);
        this.updateBlog();
      } else {
        this.addBlog();
      }
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Blog listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    addBlog() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateBlog() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getBlogById() {
      this.ready = false;
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.cleanData = helper.clone(this.form);
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/blogs");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    changeSlug(data) {
      this.form.slug = data;
    },
  },
  validations: {
    form: {
      title: {
        required,
      },
      content: {
        required,
      },
    },
  },
  computed: {
    ...mapState(["blog", "shared", "session"]),
    ...mapState({
      config: (state) => state.session.config,
    }),
    getTitle() {
      return this.$route.params.id ? "BLOG DÜZENLE" : "BLOG EKLE";
    },
    checkPermission() {
      return permission.check("blog", "u");
    },
  },
  mounted() {
    this.getBlogById();
  },
  watch: {
    "blog.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.getBlogById();
        }
      },
    },
  },
};
</script>
